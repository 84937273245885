.waiting {
  animation: waiting 1s infinite;
}

@keyframes waiting {
  0% {
    color: white;
  }
  100% {
    color: black;
  }
}
