.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 20px;
}


@media (min-width: 1000px) {
  .nav {
    flex-direction: row;
  }
}

.link {
  background: rgba(0, 0, 0, 0.05);
  color: blue;
  margin: 4px;
  position: relative;
  text-underline-position: under;
  top: -4px;
}

.active {
  color: black;
  background: crimson;
}
