@font-face {
  font-family: 'cmu_serifroman';
  src: url('fonts/cmunrm-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

h1, h2, h3, h4, h5 {
  font-weight: normal;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: 'cmu_serifroman';
}

#root {
  flex-basis: 80%;
}

button {
  font-family: 'cmu_serifroman';
}
