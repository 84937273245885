.axis {
  text-anchor: middle;
}

.line {
  stroke: rgba(0, 0, 0, 0.1);
}

.tick {
  stroke: rgba(0, 0, 0, 0.05);
}
