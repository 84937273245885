.annotation {
  position: absolute;
  width: 100%;
  height: 500px;
  left: 0;
  top: 0;
  pointer-events: none;
}

.label {
  stroke: none;
  fill: rgba(255, 0, 0, 0.5);
  font-size: 10px;
}

.marker {
  fill: none;
  stroke: rgba(255, 0, 0, 0.3);
}

.title {
  transform: translate(20, 20);
  font-size: 24px;
}
