.frame {
  width: 100%;
}

.series {
  transform: translateY(50px);
  fill: none;
  stroke: black;
}

.xAxis {
  transform: translate(0px, 1px);
}

.yAxis {
  transform: translate(50px, 50px);
}
